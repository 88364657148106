import React, { useState, useCallback, useEffect, Fragment } from 'react';
import validator from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useAxiosPrivate } from '../../../Hooks/useAxiosPrivate';
import SomethingWentWrong from '../../../Components/SomethingWentWrong';

const schema = {
    "type": "object",
    "required": ["nomineeName", "nomineeDateOfBirth", "nomineeRelation"],
    "properties": {
        "nomineeName": {
            "title": "Name",
            "type": "string"
        },
        "nomineeDateOfBirth": {
            "title": "Date of Birth",
            "type": "string",
            "format": "date"
        },
        "nomineeRelation": {
            "title": "Relation",
            "type": "string"
        }
    }
};

const uiSchema = {
    "ui:submitButtonOptions": {
        "props": {
            "className": "BBPFBtn"
        }
    }
};

function customValidate(formData, errors) {
    let dobYear = new Date(formData.nomineeDateOfBirth).getFullYear();
    let curYear = new Date().getFullYear();
    if (dobYear <= 1990 && dobYear >= curYear) {
        errors.nomineeDateOfBirth.addError(`Year should be between 1990 to ${new Date().getFullYear()}`);
    }
    return errors;
}

const NomineeDetails = ({ handleClose, customerID }) => {

    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const [btnDisable, setBtnDisable] = useState(true);
    const [errorDialog, setErrorDialog] = useState(false);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [extraErrors, setExtraErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const axiosPrivate = useAxiosPrivate();

    let yourForm;

    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const onFormSubmit = (formData) => {
        setFormData(formData);
        handleCustomerNominee(formData);
    };

    const fetchDetails = useCallback(async () => {
        try {
            let url = `customers/${customerID}`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    let tempData = {};
                    tempData.nomineeName = response.data.data.nomineeName;
                    tempData.nomineeDateOfBirth = response.data.data.nomineeDateOfBirth;
                    tempData.nomineeRelation = response.data.data.nomineeRelation;
                    setFormData(tempData);
                    setBtnDisable(false);
                } else {
                    setErrorDialog(true);
                    console.error('err.res', response.data);
                }
                setLoading(false);
            }).catch(err => {
                if (err.response) {
                    setLoading(false);
                    setErrorDialog(true);
                    console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            console.error('error', error)
        }
        // eslint-disable-next-line 
    }, []);

    const handleCustomerNominee = useCallback(async (data) => {
        try {
            setBtnDisable(true);
            let url = `customers/${customerID}/nominee`;
            let options = {
                method: 'PUT',
                url,
                data
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    setApiSuccess(true);
                } else {
                    if (typeof response.data.message === 'string') {
                        setErrorMessage(response.data.message);
                    } else {
                        let tempErr = { ...extraErrors };
                        if (response.data.message.nomineeName) {
                            tempErr.nomineeName = { __errors: [response.data.message.nomineeName[0].message] }
                        }
                        if (response.data.message.nomineeDateOfBirth) {
                            tempErr.nomineeDateOfBirth = { __errors: [response.data.message.nomineeDateOfBirth[0].message] }
                        }
                        if (response.data.message.nomineeRelation) {
                            tempErr.nomineeRelation = { __errors: [response.data.message.nomineeRelation[0].message] }
                        }
                        setExtraErrors(tempErr);
                    }
                }
                setBtnDisable(false);
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            console.error('error', error);
        }
        // eslint-disable-next-line  
    }, []);

    useEffect(() => {
        fetchDetails();
        // eslint-disable-next-line 
    }, [])

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Box component="div" className={'BBPVCDet'}>
                <Box component="div" className={'BBPVCDTitle'}>Nominee Details</Box>
                <Box component="div" className={'BBPVCDForm'}>
                    {loading ?
                        <Box component="div" className={'BBPVCDFSk'}>
                            {Array.from(Array(3).keys()).map((item) => (
                                <Stack spacing={1} key={item}>
                                    <Skeleton component="div" variant="rounded" height={21} />
                                    <Skeleton component="div" variant="rounded" height={47} />
                                </Stack>
                            ))}
                        </Box>
                        :
                        <Box component='div' className={'BBPForm'}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                validator={validator}
                                showErrorList={false}
                                omitExtraData={true}
                                customValidate={customValidate}
                                extraErrors={extraErrors}
                                onSubmit={({ formData }) => {
                                    onFormSubmit(formData);
                                }}
                                ref={(form) => { yourForm = form; }}
                            />
                        </Box>
                    }
                </Box>
                {apiSuccess && <Box component="div" className={'BBPVCDMsg'}>Updated Successfully</Box>}
                {errorMessage && <Box component="div" className={'BBPVCDErrMsg'}>{errorMessage}</Box>}
                <Box component="div" className={'BBPVCDBtn'}>
                    <Button variant="contained" className={'BBPButton BBPOButton'} disabled={btnDisable} onClick={handleClose}>Close</Button>
                    <Button variant="contained" className={'BBPButton'} disabled={btnDisable} onClick={onSubmitNew}>Submit</Button>
                </Box>
            </Box>
        </Fragment>
    );
};
export default NomineeDetails;
