import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { customizeValidator } from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { useAuth } from '../../Hooks/useAuth';
import FileUpload from '../../Components/FormFields/FileUploadNew';
import { dataUrlToFile } from '../../Utils/index';
import Backdrops from '../../Components/Backdrops';
import PageChangeDialog from '../../Components/PageChangeDialog';
import SuccessDialog from '../../Components/SuccessDialog';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import './style.css';

const schema = {
    "type": "object",
    "required": ["email", "name", "phone", "dob"],
    "properties": {
        "email": {
            "title": "Email ID",
            "type": "string",
            "format": "email"
        },
        "name": {
            "title": "Full Name",
            "type": "string"
        },
        "phone": {
            "title": "Mobile Number",
            "type": "string",
            "format": 'Number',
            "minLength": 10,
            "maxLength": 10
        },
        "dob": {
            "title": "Date of Birth",
            "type": "string",
            "format": "date"
        },
        "file": {
            "title": "Profile Image",
            "type": "string",
        }
    }
};

const customFormats = {
    'Number': /^[0-9]+$/
};

const validator = customizeValidator({ customFormats });

const GeneralSettings = () => {

    const { auth } = useAuth();

    let widgets = {
        fileUpload: FileUpload,
    }

    const axiosPrivate = useAxiosPrivate();
    let navigate = useNavigate();

    const [imageKey, setImageKey] = useState('');

    const [formData, setFormData] = useState({});
    const [loading, setSetLoading] = useState(false);

    const [errorDialog, setErrorDialog] = useState(false);
    const [submitDialog, setSubmitDialog] = useState(false);
    const [onSubmitLoading, setOnSubmitLoading] = useState(false);
    const [changePage, setChangePage] = useState(false);
    const [duplicateUser, setDuplicateUser] = useState({ error: false, msg: '' });

    const uiSchema = {
        "ui:submitButtonOptions": {
            "props": {
                "className": "BBPFBtn"
            }
        },
        "file": {
            "ui:widget": "fileUpload",
            "ui:options": {
                accept: ".png, .jpeg, .jpg",
                imageKey: imageKey
            },
        }
    };

    let yourForm;

    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const onFormSubmit = (formData) => {
        setFormData(formData);
        let tempData = { ...formData };
        if (tempData.file) {
            tempData.file = dataUrlToFile(tempData.file, 'bbp');
        }
        handleUpdateUser(tempData);
    };

    const fetchUserValue = useCallback(async () => {
        try {
            setSetLoading(true);
            setChangePage(true);
            let url = `settings`;
            let options = {
                method: 'GET',
                url
            };
            await axiosPrivate(options).then(response => {
                if (response.data.status === 1) {
                    let tempData = {};
                    tempData.name = response.data.data.name;
                    tempData.email = response.data.data.email;
                    tempData.phone = response.data.data.phone;
                    tempData.dob = response.data.data.dob;
                    tempData.status = "Yes";
                    setImageKey(response.data.data.image);
                    setFormData(tempData);
                    setSetLoading(false);
                    setChangePage(false);
                } else {
                    setSetLoading(false);
                    setChangePage(false);
                }
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setSetLoading(false);
                    setChangePage(false);
                    console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setSetLoading(false);
            setChangePage(false);
            console.error('error', error);
        }
        // eslint-disable-next-line 
    }, []);

    const handleUpdateUser = useCallback(async (data) => {
        try {
            setOnSubmitLoading(true);
            setChangePage(true);
            let url = `settings`;
            let options = {
                method: 'PUT',
                url,
                data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await axiosPrivate(options).then(response => {
                if (response.data && response.data.status === 1) {
                    setSubmitDialog(true);
                    setOnSubmitLoading(false);
                    setChangePage(false);
                    setDuplicateUser({ error: false, msg: "" });
                } else {
                    setOnSubmitLoading(false);
                    setChangePage(false);
                    setDuplicateUser({ error: true, msg: response.data.message });
                }
            }).catch(err => {
                if (err.response) {
                    setErrorDialog(true);
                    setOnSubmitLoading(false);
                    setChangePage(false);
                    console.error('err.res', err.response.data);
                }
            });
        } catch (error) {
            setErrorDialog(true);
            setOnSubmitLoading(false);
            setChangePage(false);
            console.error('error', error);
        }
    }, [auth.id]);

    const handleSubmitDialogClose = () => {
        setSubmitDialog(false);
        navigate(0);
    };

    useEffect(() => {
        fetchUserValue();
        // eslint-disable-next-line 
    }, []);

    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <PageChangeDialog showDialog={changePage} setShowDialog={setChangePage} />
            <Backdrops
                open={onSubmitLoading}
                title={'Updating'}
            />
            <SuccessDialog
                open={submitDialog}
                onClose={handleSubmitDialogClose}
                title={'Updated Successfully'}
                message={'Updated Successfully'}
                buttonTitle={'Close'}
            />
            <Box component="div" className={'BBPSPTView'}>
                <Box component="div" className={'BBPSPTVForm'}>
                    {loading ?
                        <Box component='div' className={'BBPSPTVFFLoad'}>
                            {Array.from(Array(6).keys()).map((item) => (
                                <Stack spacing={1} key={item} className={"BBPSPTVFFLInner"}>
                                    <Skeleton variant="rectangular" height={22} />
                                    <Skeleton variant="rectangular" height={55} />
                                </Stack>
                            ))}
                        </Box> :
                        <Box component='div' className={'BBPForm'}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                validator={validator}
                                widgets={widgets}
                                showErrorList={false}
                                omitExtraData={true}
                                onSubmit={({ formData }) => {
                                    onFormSubmit(formData);
                                }}
                                ref={(form) => { yourForm = form; }}
                            />
                        </Box>
                    }
                </Box>
                {duplicateUser.error &&
                    <Box component='div' className={'BBPSPTVErr'}>
                        {duplicateUser.msg}
                    </Box>}
                <Box component="div" className={'BBPSPTVBtn'}>
                    <Button variant="contained" className={'BBPButton'} onClick={onSubmitNew}>Submit</Button>
                </Box>
            </Box>
        </Fragment>
    );
};

export default GeneralSettings; 